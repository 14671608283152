<template>
  <div class="wrapper">
    <!--TODO: this back button should be a separate component  -->
    <button @click="backButtonHandler" class="back-button">
      <IconLeftArrow />
      <p>
        {{ $t('Balances.IndividualBalance.Back').value }}
      </p>
    </button>
    <TransactionShortcutButtons class="mx-4" />
    <h3 class="page-title">
      {{ $t('ComponentBalancesOverviewList.Balances').value }}
    </h3>

    <AppList class="list">
      <AppListItem class="table-header">
        <template #left>
          <div class="header">
            <p class="header-left">
              {{ $t('ComponentBalancesOverviewList.CurrencyLabel').value }}
            </p>
            <div class="header-right">
              <p>
                {{ $t('ComponentBalancesOverviewList.TotalLabel').value }}
              </p>
              <p>
                {{ $t('ComponentBalancesOverviewList.AvailableLabel').value }}
              </p>
            </div>
          </div>
        </template>
      </AppListItem>
      <BalancesOverviewItem
        v-if="!isLoading"
        v-for="balance in balancesList"
        :key="balance.accountCurrency"
        :balance-account="balance"
      />

      <AppSkeleton
        v-if="isLoading"
        class="balance-items-loading"
        :loading="isLoading"
        height="40px"
        :count="5"
      />
    </AppList>
  </div>
</template>

<script>
import { useI18nStore, useBalancesStore, useAppStore, useCountriesStore } from '@galileo/stores'
import { ref, computed, onBeforeMount } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'

import { AppCard, AppList, AppListItem, AppSpinnerBig, AppSkeleton } from '@oen.web.vue2/ui'
import BalancesOverviewItem from '@galileo/components/Views/Balances/BalancesOverviewItem'
import TransactionShortcutButtons from '@galileo/components/Views/Balances/TransactionShortcutButtons/TransactionShortcutButtons'
import { IconLeftArrow } from '@galileo/assets/icons/vue'

export default {
  name: 'BalancesOverview',
  components: {
    AppCard,
    AppList,
    AppListItem,
    BalancesOverviewItem,
    AppSpinnerBig,
    AppSkeleton,
    TransactionShortcutButtons,
    IconLeftArrow,
  },
  setup() {
    const { $t } = useI18nStore()
    const balancesStore = useBalancesStore()
    const appStore = useAppStore()
    const countriesStore = useCountriesStore()
    const router = useRouter()
    const isLoading = ref(false)

    const defaultBalanceAccount = computed(() => {
      const defaultCurrency = balancesStore.currenciesList.filter(
        (ccy) => ccy.value === countriesStore.getCountryDefaultCurrency
      )
      if(defaultCurrency) {
        return defaultCurrency.map((ccy) => {
          return {
            ...ccy,
            accountCurrency: ccy.value,
            totalBalance: 0,
            availableBalance: 0
          }  
        })
      }
    })

    onBeforeMount(async () => {
      if (
        appStore.currentRoute.from.name !== 'FundBalance' &&
        appStore.currentRoute.from.name !== 'ConvertBalances' &&
        appStore.currentRoute.from.name !== 'OneStepTransfer' &&
        appStore.currentRoute.from.name !== 'IndividualCurrency'
      ) {
        appStore.accessBalancesListFromPath = appStore.currentRoute.from.path
      }
      isLoading.value = true
      await balancesStore.fetchUserActiveBalanceList()
      isLoading.value = false
    })

    const backButtonHandler = () => {
      router.replace({ path: appStore.accessBalancesListFromPath })
    }

    const balancesList = computed(() => {
      if (balancesStore.userActiveBalanceList.length > 0) {
        return balancesStore.userActiveBalanceList
      } 
      return defaultBalanceAccount.value
    })

    return {
      $t,
      backButtonHandler,
      balancesList,
      isLoading,
    }
  },
}
</script>

<style scoped>
.wrapper {
  @apply flex flex-col w-full max-w-5xl mx-auto gap-6;
}
.page-title {
  @apply text-2xl font-semibold text-grayblue-400 mx-4;
}

.list {
  @apply mx-4 px-5 mt-0 py-3 bg-white  rounded-xl mb-12;
  @screen sm {
    @apply px-10;
  }
}
.header {
  @apply flex justify-between;
  color: rgba(136, 144, 160, 1);
}
.header-left {
  @apply text-xs font-semibold text-left;
}
.header-right {
  width: 48%;
  @apply flex self-center text-xs font-semibold text-left;
  p {
    @apply w-1/2;
    @apply text-right;
  }
}
::v-deep .list-item {
  border-bottom: none;
}
.table-header {
  border-bottom: 1px solid rgba(232, 235, 237, 1);
  @apply mb-2;
}
::v-deep .balance-items-loading {
  @apply mt-4;
  .skeleton {
    @apply rounded-lg mb-6 bg-gray-400;
  }
}
.back-button {
  @apply flex flex-row mt-10 items-center gap-2 mx-4 w-max-content text-grayblue-300 font-semibold;
}
@screen sm {
  .back-button {
    @apply mt-0;
  }
}
</style>
