import * as EuronetFlags from '@oen.web.vue2/flags'

export function getFlag(option) {
  let flag = null
  if (option) {
    flag = 'Flag' + option.substring(0, option.length - 1)
  }
  if (flag === 'FlagKV') {
    flag = 'FlagCV'
  }
  return EuronetFlags[flag] || null
}
