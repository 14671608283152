<template>
  <div class="button-list">
    <AppButton
      @click="goToQuickTransfer"
      class="blue-button"
      data-test-id="transaction-shortcut-button-qt"
    >
      <IconBolt />
      <p>
        {{ $t('Balances.TransactionShortcutButtons.QuickTransfer').value }}
      </p>
    </AppButton>
    <AppButton
      @click="goToAddFunds"
      class="transparent-button"
      data-test-id="transaction-shortcut-button-addFunds"
    >
      <AppIcon class="-ml-1">
        <IconAdd />
      </AppIcon>
      <p>
        {{ $t('Balances.TransactionShortcutButtons.AddFunds').value }}
      </p>
    </AppButton>
    <AppButton
      @click="goToConvert"
      class="transparent-button"
      data-test-id="transaction-shortcut-button-convert"
    >
      <IconRecycle />
      <p>
        {{ $t('Balances.TransactionShortcutButtons.Convert').value }}
      </p>
    </AppButton>
  </div>
</template>

<script>
import { IconAdd, IconRedo } from '@oen.web.vue2/icons'
import { useI18nStore, useAppStore } from '@galileo/stores'
import { AppIcon } from '@oen.web.vue2/ui'
import IconBolt from '@galileo/assets/icons/vue/IconBolt'
import IconRecycle from '@galileo/assets/icons/vue/IconRecycle'
import AppButton from '@oen.web.vue2/ui/src/components/AppButton/AppButton.vue'
import { useRouter } from '@galileo/composables/useRouter'
import { SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

export default {
  name: 'TransactionShortcutButtons',
  components: { AppIcon, IconBolt, IconAdd, IconRedo, IconRecycle, AppButton },
  setup() {
    const router = useRouter()
    const { $t } = useI18nStore()
    const appStore = useAppStore()

    const goToQuickTransfer = () => {
      router.push('/one-step-transfer')
    }
    const goToAddFunds = () => {
      if (appStore.currentRoute.name === 'IndividualCurrency') {
        router.push({
          name: 'FundBalance',
          params: {
            locationFrom: SEGMENT_LOCATIONS.INDIVIDUAL_CURRENCY,
            currency: appStore.currentRoute.params.currency,
          },
        })
      } else if (appStore.currentRoute.name === 'Activity') {
        router.push({ name: 'FundBalance', params: { locationFrom: SEGMENT_LOCATIONS.FUND } })
      } else {
        router.push({ name: 'FundBalance' })
      }
    }
    const goToConvert = () => {
      router.push('/convert-balance')
    }

    return {
      $t,
      goToQuickTransfer,
      goToAddFunds,
      goToConvert,
    }
  },
}
</script>

<style scoped>
.button-list {
  @apply flex flex-wrap gap-3 z-0;
}
::v-deep .button.button--primary {
  @apply h-8 w-max-content leading-5 px-3 rounded-xl flex flex-row items-center font-semibold;
  &.blue-button {
    @apply bg-blue-100  text-blue-400;
    &:hover {
      @apply bg-blue-hover;
    }
  }
  &.transparent-button {
    @apply bg-transparent text-grayblue-300 px-1;
    &:hover {
      @apply bg-gray-400;
    }
  }
}

::v-deep .button.button--primary .button-inner {
  @apply p-0 h-full w-full;
  p {
    @apply ml-2 text-sm;
  }
}

@screen sm {
  ::v-deep .button.button--primary {
    @apply h-9 text-xl px-5;
    &.transparent-button {
      @apply px-5;
    }
    .button-inner p {
      @apply text-base;
    }
  }
}
</style>
