<template>
  <AppListItem
    class="balance-item"
    analytics-name="balance-overview-item"
    @itemClick="$router.push({ path: `/balances/${balanceAccount.accountCurrency}` })"
  >
    <template #figure>
      <CircularFlag
        size="30"
        class="country-flag"
        :currency-code="balanceAccount.accountCurrency"
      />
    </template>
    <template #left>
      <div class="content">
        <div class="currency-title">
          <AppListItemTitle v-if="mobileView" class="balance-title">
            {{ balanceAccount.accountCurrency }}
          </AppListItemTitle>
          <div v-else>
            <AppListItemTitle class="balance-title">
              {{
                $t('ComponentBalancesOverviewItem.BalanceTitle', {
                  currency: balanceAccount.accountCurrency,
                }).value
              }}
            </AppListItemTitle>
            <AppListItemCaption data-test="account.description" class="currency-full-name">
              {{ balanceAccount.description }}
            </AppListItemCaption>
          </div>
        </div>
        <div v-if="mobileView" class="amounts mobile-amounts">
          <p>{{ formatShortenCurrency(balanceAccount.totalBalance) }}</p>
          <p>{{ formatShortenCurrency(balanceAccount.availableBalance) }}</p>
        </div>
        <div v-else class="amounts">
          <p data-test="balance.total">
            {{ formatCurrency(balanceAccount.accountCurrency, balanceAccount.totalBalance) }}
            <span>{{ balanceAccount.accountCurrency }}</span>
          </p>
          <p data-test="balance.available">
            {{ formatCurrency(balanceAccount.accountCurrency, balanceAccount.availableBalance) }}
            <span>{{ balanceAccount.accountCurrency }}</span>
          </p>
        </div>
      </div>
    </template>
  </AppListItem>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useI18nStore } from '@galileo/stores'
import CircularFlag from '@galileo/components/CircularFlag/CircularFlag.vue'
import { formatCurrency, formatShortenCurrency } from '@galileo/composables/useCurrency'

import {
  AppListItem,
  AppFlag,
  AppListItemTitle,
  AppListItemCaption,
  useMediaQuery,
} from '@oen.web.vue2/ui'
import { getFlag } from '@galileo/composables/useFlag'

export default {
  name: 'BalancesOverviewItem',
  components: {
    AppListItem,
    AppFlag,
    AppListItemTitle,
    AppListItemCaption,
    CircularFlag,
  },
  props: {
    balanceAccount: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()

    const mq = useMediaQuery()
    const mobileView = computed(() => mq.current === 'xs')

    return {
      $t,
      getFlag,
      mobileView,
      formatCurrency,
      formatShortenCurrency,
    }
  },
}
</script>

<style scoped>
.balance-item {
  @apply mb-4;
}
::v-deep .flag {
  @apply self-center overflow-hidden w-10 rounded-full;
  height: 40px !important;
  .flag-wrapper {
    @apply left-2;
    transform: scale(2.7);
  }
}
.content {
  @apply flex justify-between;
}
.currency-title {
  @apply flex flex-col justify-center;
}
.balance-title {
  @apply text-sm font-medium leading-4 text-primary-darker;
}
.currency-full-name {
  @apply font-normal text-xs leading-4;
  color: rgba(136, 144, 160, 1);
}
.amounts {
  @apply flex self-center text-sm font-medium w-1/2 text-grayblue-400;
  p {
    width: 50%;
    @apply text-right;
  }
}
.mobile-amounts {
  width: 60%;
}
::v-deep .list-item-content {
  @apply py-2 px-0;
}
</style>
